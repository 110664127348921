<template>
  <a
    class="hotel-needed-warning err"
    @click="route_to_hotel_index()"
  >
    {{ $t('h4h.addHotel') }}
  </a>
</template>
<script>
export default {
  props: {
    eventid: {
      type: Number,
      default: null
    }
  },
  methods: {
    route_to_hotel_index(){
      if (window.location.pathname == `/cart/events/${this.eventid}/hotels`){
        document.getElementsByClassName('currency-and-close')[0].getElementsByTagName('button')[0].click()
      } else {
        window.location.href = `/cart/events/${this.eventid}/hotels`
      }
    }
  },
}
</script>
