<template>
  <div class="cart-empty">
    <div class="message">
      {{ $t('cart.cartEmpty') }}
    </div>
    <div>
      <a
        href="/packages"
        class="btn btn-secondary"
      >{{ $t('cart.continueShopping') }}</a>
    </div>
  </div>
</template>

<script>
export default {
  created(){
    // when showing the continue shopping button on checkout
    // shrink checkout page and scroll to top
    let addressEl = document.getElementById('checkoutAddress')
    if (addressEl != null) {
      addressEl.classList.add('no-display')
      window.scrollTo({top: 120})
    }
  }
}
</script>
