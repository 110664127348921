import DefaultCustomersLogin from './login'
import DefaultCustomersSignUp from './signup'
import DefaultCustomersNewLoginSignup from './newLoginSignup'
import GuestForm from './guestForm'
import Cart from '../../shared/cart/cart'
import { store } from'../../default/store'
import VueLoader from '../vue-loader'
const Rails = require('@rails/ujs')
Rails.start()

document.addEventListener('turbo:load', () => {
  VueLoader.loadVueComponent(DefaultCustomersNewLoginSignup, document.getElementById('emailForm'), store)
  VueLoader.loadVueComponent(DefaultCustomersLogin, document.getElementById('loginForm'), store)
  VueLoader.loadVueComponent(DefaultCustomersSignUp, document.getElementById('signupForm'), store)
  VueLoader.loadVueComponent(GuestForm, document.getElementById('guestForm'), store)
  VueLoader.loadVueComponent(Cart, document.getElementById('credentialsCart'), store)
})
