<template>
  <div>
    <div
      v-if="currencies && currencies.length > 1"
      class="field"
    >
      <div class="form-group currency_exchange_form notranslate control">
        <select
          id="currency"
          v-model="current"
          class="form-control currency select"
          @change="updateCurrency()"
        >
          <option
            v-for="newCurrency in currencies"
            :key="newCurrency"
            :value="newCurrency"
          >
            {{ newCurrency }}
          </option>
        </select>
      </div>
    </div>
  </div>
</template>

<script>
import { mapMutations } from 'vuex'
import CurrencyApi from './currency-api'

export default {
  props: {
    chargeablecurrencies: {
      default: undefined,
      type: Array
    }
  },
  data() {
    return {
      current: 'USD',
      error_message: []
    }
  },
  computed: {
    orderCurrency(){
      return this.$store.state.order.order.currency
    },
    // currencies() {
    //   return this.$store.state.order.currencies
    // },
    currencies: {
      get() {
        return this.$store.state.order.currencies
      },
      set(currencies) {
        this._chargeablecurrencies = currencies
      }
    },
    order(){
      return this.$store.state.order.order
    }
  },
  watch: {
    chargeablecurrencies() {
      // this.currencies = this.order.currencies
    },
    order(){
      this.current = this.order.currency
    }
  },

  created() {
    if (this.chargeablecurrencies == undefined) {
      // Use all currencies for Event Pages
      // this.current = this.$store.state.order.currentCurrency
      this.fetchCurrencies()
    } else {
      // Only use chargeableCurrencies for cart/order vues
      this.updateCurrencies(this.order.currencies)
      this.current = this.order.currency
      this.setOptions()
    }
  },
  methods: {
    ...mapMutations('order', ['updateOrder', 'updateLoading', 'updateCurrencies', 'updateCurrentCurrency', 'updateExchangeRate']),

    ...mapMutations('order', ['updateOrder', 'updateLoading']),
    fetchCurrencies() {
      CurrencyApi.availableCurrencies().then(currencies => {
        this.currencies = currencies.currencies
        this.current = currencies.current_currency
        this.$emit('currency-emit', this.error_message, this.current, Number(currencies.rate))

        if(document.getElementById('packageComparisonPage')){
          // must update currency on package page because it is not a vue, but a decorated page
          this.updateCurrency()
        }
      })
    },

    updateCurrency() {
      this.updateLoading(true)
      let applyCredit = this.$store.state.checkout.applyCredit
      CurrencyApi.updateOrderRatesReturnOrder(this.current, applyCredit).then((order) => {
        if (order.exchange_rate === 'rate not updated') {
          this.error_message.push('Curreny was not able to update')
          this.$emit('currency-emit', this.error_message)
          this.error_message = []
        } else {
          this.updateOrder(order)
          this.updateCurrentCurrency(order.currency)
          this.updateExchangeRate(order.exchange_rate)
          this.current = order.currency
          let prices = null

          if(window.name == 'qah')
            prices = document.querySelectorAll('.group-package .row-wrapper .price')
          else
            prices = document.querySelectorAll('.group-package .product-item .price')
          for (let price of prices) {
            let data_price = price.getAttribute('data-price')
            price.innerText = CurrencyApi.formatAmount(data_price * Number(this.order.exchange_rate), this.current)
          }
          this.$emit('currency-emit', this.error_message, this.current, Number(this.order.exchange_rate))
        }
        this.updateLoading(false)
      })
    },

    setOptions() {
      // Sets form values if they are unset.  If you use the back button, the form is rendered blank
      // 'Selected' does not affect state of form, only what is originally displayed
      // updating currencies causes form to be rendered blank
      if( document.getElementById('vue_package_id') && document.getElementById('vue_hotel_id') &&
          document.getElementById('bottom_form_customer_qty') && !document.getElementById('vue_hotel_id').value) {
        document.getElementById('vue_package_id').getElementsByTagName('option')[0].selected = 'selected'
        document.getElementById('vue_hotel_id').getElementsByTagName('option')[0].selected = 'selected'
        document.getElementById('bottom_form_customer_qty').getElementsByTagName('option')[0].selected = 'selected'
      }
    }
  }
}
</script>

<style scoped>
</style>
