<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div
          id="quote-modal-container"
          class="quote-modal-container"
        >
          <div
            class="modal-close-icon"
            @click="close"
          >
            X
          </div>
          <div
            class="quote-modal-header"
          >
            <h3>
              {{ name }} Inclusions
            </h3>
          </div>
          <div class="quote-modal-body">
            <p
              v-for="quoteProductNames in productNames"
              :key="quoteProductNames"
            >
              {{ quoteProductNames }}
            </p>
          </div>
          <div class="quote-modal-footer">
            <slot name="footer">
              <button
                class="btn btn-secondary"
                @click="close"
              >
                Close
              </button>
            </slot>
          </div>
        </div>
      </div>
    </div>
  </transition>
</template>
<script>

export default {
  name: 'QuoteProductsModal',

  props: {
    eventname: {
      default: null,
      type: String
    },
    name: {
      default: null,
      type: String
    },
    productNames: {
      default: () => [],
      type: Array
    }
  },

  methods: {
    close() {
      this.$emit('close')
    }
  },
}
</script>