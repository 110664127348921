<template>
  <section class="package-hotel-item">
    <div class="single-item cart-edit-package">
      <div class="desc-quantity-holder">
        <ItemDescription
          :itemid="item.id"
          :name="item.package_display_name"
          :price="item.price_decorated"
          :eventname="item.event_display_name"
          :package-id="item.package_id"
          :candelete="cartdisabled ? 'false': 'true'"
          :cartdisabled="cartdisabled"
        />

        <ItemQty
          :item="item"
          :quantity="item.quantity"
          :maxqty="maxPackageQty"
          :updating="updating"
          :candelete="cartdisabled ? 'false': 'true'"
          :cartdisabled="cartdisabled"
          @update-qty="updatePackage"
          @delete-item="deleteItem"
          @errors="ers = $event"
        />
      </div>
      <div class="delete-holder">
        <i
          v-if="!cartdisabled"
          class="ion ion-md-close-circle-outline"
          @click="deleteItem"
        />
      </div>
    </div>
    <div
      v-if="ers"
      class="errors"
    >
      <span>{{ ers }}</span>
      <i
        class="ion ion-md-close-circle-outline"
        @click="ers = null"
      />
    </div>
    <div class="cart-edit-hotel single-item">
      <div class="desc-quantity-holder">
        <ItemDescription
          :itemid="item.id"
          :name="hotel_display_name"
          price=""
          eventname=""
          candelete="false"
        />

        <ItemQty
          :item="item"
          :quantity="item.room_quantity"
          :hotel="true"
          :maxqty="maxHotelQty"
          :minqty="minHotelQty"
          :updating="updating"
          candelete="false"
          @update-qty="updateHotel"
        />
      </div>
      <div class="delete-holder" />
    </div>
    <div
      v-if="item.transportation_fee_decorated"
      class="item-transportation single-item flex-grow"
    >
      <div class="desc-quantity-holder">
        <ItemDescription
          :itemid="item.id"
          :name="eventTransportationText"
          price=""
          eventname=""
          candelete="false"
        />

        <div class="elements-item-quantity">
          <span> {{ $t('confirmation.quantity') }}</span>
          <div class="item-amount">
            {{ item.contract_transportation_quantity }}
          </div>
        </div>
      </div>
      <div class="delete-holder" />
    </div>
  </section>
</template>

<script>
import ItemDescription from './item_description'
import ItemQty from './item_quantity'
import PackageAndHotelJs from '../package_and_hotel_item_shared_methods'

export default Object.assign({}, PackageAndHotelJs, {
  components: {
    ItemDescription,
    ItemQty
  }
})
</script>
