<template>
  <section class="package-hotel-item">
    <ItemDescription
      :itemid="item.id"
      :item="item"
      :name="item.package_display_name"
      :price="item.package_price_total_decorated"
      :eventname="item.event_display_name"
      :eventlabel="item.event_label"
      :package-id="item.package_id"
      candelete="true"
    />

    <div
      v-if="ers"
      class="errors"
    >
      <span>{{ ers }}</span>
      <span
        class="delete"
        @click="ers = null"
      />
    </div>

    <ItemQty
      :class="[ item.package_display_name.count < 39 ? 'overhead-margin' : '' ]"
      :quantity="item.quantity"
      :maxqty="maxPackageQty"
      :updating="updating"
      :item="item"
      candelete="true"
      @update-qty="updatePackage"
      @delete-item="deleteItem"
    />

    <ItemDescription
      :item="item"
      :itemid="item.id"
      :hotel-id="item.hotel_id"
      :name="hotel_display_name"
      :price="item.hotel_price_total_decorated"
      :check-in="assignCheckIn"
      :check-out="assignCheckOut"
      :contracted="isContracted"
      eventname=""
      candelete="false"
    />

    <ItemQty
      :class="[ hotel_display_name.length > 39 ? 'overhead-margin' : '' ]"
      :quantity="item.room_quantity"
      :maxqty="maxHotelQty"
      :item="item"
      :updating="updating"
      candelete="false"
      @update-qty="updateHotel"
    />

    <div
      v-if="item.transportation_fee_decorated"
      class="item-transportation"
    >
      <ItemDescription
        :item="item"
        :itemid="item.id"
        :name="transportationFee"
        price=""
        eventname=""
        candelete="false"
      />

      <p class="event-name">
        {{ transportationText }}
      </p>
      <p class="item-amount transportation-cart-item-quantity">
        {{ item.quantity }}
      </p>
    </div>
  </section>
</template>

<script>
import ItemDescription from './item_description'
import ItemQty from '../version_1/item_quantity'
import PackageAndHotelJs from '../package_and_hotel_item_shared_methods'
import { store } from'../../../../default/store/index.js'

export default Object.assign({}, PackageAndHotelJs, {
  components: {
    ItemDescription,
    ItemQty,
    store
  }
})
</script>
