<template>
  <section
    v-if="!item.deposit"
    class="order-item"
    :class="[h4hHotelAddOn ? 'h4h-order-item' : null, isDiscount ? 'hidden' : '', isParkingPass ? 'parking-pass-item' : '']"
  >
    <div
      v-if="cartEditPage"
      class="item-header"
    >
      <h3
        class="brand-primary" 
        :class="isParkingPass ? 'hidden' : ''"
      >
        {{ item.event_display_name || order.event_name }}
      </h3>
    </div>
    <component
      :is="componentToRender"
      :item="item"
      :showhotels="showhotels"
      :cartdisabled="cartDisabled"
    />
  </section>
</template>

<script>
import HotelOnlyVersion1 from './version_1/hotel_only_item'
import PackageOnlyVersion1 from './version_1/package_only_item'
import PackageAndHotelVersion1 from './version_1/package_and_hotel_item'
import PackageAndHotelH4H from './h4h/package_and_hotel_item'
import PackageOnlyH4H from './h4h/package_only'
import H4hHotelAddOn from './version_1/h4h_post_purchase_item'

import HotelOnlyElements from './elements/hotel_only_item'
import PackageOnlyElements from './elements/package_only_item'
import PackageAndHotelElements from './elements/package_and_hotel_item'
// import H4hHotelForTicket from './version_1/h4h_hotel_for_ticket'

export default {

  components: {
    HotelOnlyVersion1,
    PackageAndHotelH4H,
    PackageAndHotelVersion1,
    PackageOnlyH4H,
    PackageOnlyVersion1,
    H4hHotelAddOn,
    PackageOnlyElements,
    PackageAndHotelElements,
    HotelOnlyElements,
    // H4hHotelForTicket
  },

  props: {
    cartversion: {
      default: 'version_1',
      type: String,
      required: false,
    },

    item: {
      default: () => {},
      required: true,
      type: Object
    },
    showhotels: {
      default: '',
      required: false,
      type: String
    }
  },

  
  computed: {
    componentToRender() {
      if(this.elementsPackageOnly) {
        return PackageOnlyElements
      } else if(this.elementsHotelOnly) {
        return HotelOnlyElements
      } else if (this.elementsPackageAndHotel) {
        return PackageAndHotelElements
      }  else if(this.packageOnlyH4H) {
        return PackageOnlyH4H
      } else if (this.packageOnly) {
        return PackageOnlyVersion1
      } else if(this.hotelForTicket) {
        return H4hHotelAddOn
      }
      else if (this.hotelOnly) {
        return HotelOnlyVersion1
      } else if (this.h4hHotelAddOn){
        return H4hHotelAddOn
      } else if (this.h4hItemDisplay) {
        return PackageAndHotelH4H
      } else {
        return PackageAndHotelVersion1
      }
    },
    // blankH4HHotel(){
    //   return !this.item.hotel_name && !this.item.hotel_price_total_decorated
    // },

    elementsHotelOnly(){
      return this.cartEditPage && this.h4hHotelAddOn
    },

    elementsPackageOnly(){
      return this.cartEditPage && this.packageOnly
    },

    elementsPackageAndHotel(){
      return this.cartEditPage && !this.packageOnly && !this.hotelOnly
    },

    cartEditPage(){
      return document.getElementById('cartEdit')
    },

    hotelForTicket(){
      return this.item.h4h_hotel_for_ticket
    },

    hotelOnly() {
      return this.item.package_price <= 0 &&
        !this.item.h4h_hotel_add_on &&
        !this.item.package_hotel_required &&
        !this.item.package_display_name
    },

    h4hItemDisplay() {
      return this.cartversion == 'h4h'
    },

    isDiscount() {
      return this.item.price < 0 && !this.item.deposit
    },

    isParkingPass() {
      return this.item.sf_type == 'Parking Pass' 
    },

    order(){
      return this.$store.state.order.order
    },

    h4hHotelAddOn(){
      return this.item.h4h_hotel_add_on || this.item.h4h_hotel_for_ticket
    },

    // retailHotelAddOn(){
    //   return this.item.h4h_hotel_add_on && this.item.hotel_display_name != 'No Accommodation'
    // },

    packageOnly() {
      return (this.item.hotel_id == null && !this.item.h4h_hotel_add_on &&
        Object.entries(this.item.hotel_reservations).length == 0)
      // above method will look at the vue observer object and present 0 if object is empty
      // if we originally send an empty object to this.item.hotel_reservations, the observer
      // object will not retain it's value.
    },

    packageOnlyH4H() {
      return this.h4hItemDisplay && this.packageOnly
    },

    cartDisabled(){
      return (this.order.disabled_cart)
    }
  }
}
</script>
