import CartApi from '../../shared/cart/cart_api'
import FetchCall from '../../shared/local_fetch_call'

const getCurrentRates = function(currency) {
  return FetchCall.fetchWithoutLocale('/api/exchange_rates.json', 'GET', null).
    then(res => {
      if (res.rates) {
        return res.rates[currency]
      } else {
        return 'rate not updated'
      }
    }).catch(() => {
    // TODO: handle errors
      return 'rate not updated'
    })
}

export default {
  availableCurrencies() {
    let path = '/api/currencies.json'
    let event = document.querySelector('.event')

    if (event != null) {
      let eventId = event.dataset.eventId || event.dataset.eventid
      path = `/api/currencies.json?event_id=${eventId}`
    }

    return FetchCall.fetchWithoutLocale(path, 'GET', null).
      then(res => {
        if (res.rate) {
          return res
        } else {
          return { rate: 1, currencies: ['USD'] }
        }
      })
  },

  checkCurrentRates(currency, rate) {
    return getCurrentRates(currency).then((currentRate) => {
      if (Number(currentRate) == rate) {
        // Rate is the same, return same values
        return { currency: currency, rate: rate }
      } else if (currentRate == 'rate not updated') {
        // Rate had an error, update order currency to USD
        return this.updateOrderRates('USD').then((currentRate) => {
          return { currency: 'USD', rate: currentRate }
        })
      } else {
        // Rate was different, update order
        return this.updateOrderRates(currency).then((currentRate) => {
          return { currency: currency, rate: currentRate }
        })
      }
    })
  },

  formatAmount(amount, currency) {
    if(currency) {
      let total = Number(amount).toFixed(2)
      if (currency == 'USD') {
        return '$' + total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {

        return new Intl.NumberFormat((navigator.language || 'en-US'), { style: 'currency',
          currency: currency }).format(total)
      }
    }
  },

  formatAmountNoCents(amount, currency) {
    return new Intl.NumberFormat((navigator.language || 'en-US'),
      { style: 'currency',
        currency: currency,
        minimumFractionDigits: 0,
        maximumFractionDigits: 0
      }).format(Number(amount).toFixed(0))
  },

  getCurrentCurrency() {
    return FetchCall.fetchWithoutLocale('/api/currency.json', 'GET', null).
      then(res => { return res })
  },

  updateOrderRatesReturnOrder(currency, applyCredit=true) {
    let body = JSON.stringify(
      { order: { currency: currency, apply_credit: applyCredit } }
    )
    return CartApi.changeOrder(body).then(order => {
      if (order.id) {
        if(document.getElementById('h4hHotelIndex')){this.updateHotelCurrencies(order)}
        return order
      } else {
        return 'rate not updated'
      }
    })
  },
  updateOrderRates(currency, applyCredit=true) {
    let body = JSON.stringify(
      { order: { currency: currency, apply_credit: applyCredit } }
    )
    return CartApi.changeOrder(body).then(order => {
      if (order.id) {
        if(document.getElementById('h4hHotelIndex')){this.updateHotelCurrencies(order)}
        return order.exchange_rate
      } else {
        return 'rate not updated'
      }
    })
  },

  updateHotelCurrencies(order){
    document.getElementById('updateOrderCurrency').setAttribute('data-currency', order.currency)
    document.getElementById('updateOrderCurrency').setAttribute('data-rate', order.exchange_rate)
    document.getElementById('updateOrderCurrency').click()
  }
}
