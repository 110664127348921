<template>
  <section class="package-hotel-item">
    <div class="single-item border-bottom-ccc">
      <ItemDescription
        :itemid="item.id"
        :name="item.package_display_name"
        :price="item.price_decorated"
        :eventname="item.event_display_name"
        :package-id="item.package_id"
        :candelete="cartdisabled ? 'false': 'true'"
        :cartdisabled="cartdisabled"
      />

      <div
        v-if="ers"
        class="errors"
      >
        <span>{{ ers }}</span>
        <span
          class="delete"
          @click="ers = null"
        />
      </div>

      <ItemQty
        :item="item"
        :quantity="item.quantity"
        :maxqty="maxPackageQty"
        :updating="updating"
        :candelete="cartdisabled ? 'false': 'true'"
        :cartdisabled="cartdisabled"
        @update-qty="updatePackage"
        @delete-item="deleteItem"
      />
    </div>
    <div class="single-item">
      <ItemDescription
        :itemid="item.id"
        :name="hotel_display_name"
        price=""
        eventname=""
        candelete="false"
      />

      <ItemQty
        :item="item"
        :quantity="item.room_quantity"
        :hotel="true"
        :maxqty="maxHotelQty"
        :minqty="minHotelQty"
        :updating="updating"
        candelete="false"
        @update-qty="updateHotel"
      />
    </div>
    <div
      v-if="item.transportation_fee_decorated"
      class="item-transportation single-item flex-grow"
    >
      <ItemDescription
        :itemid="item.id"
        :name="eventTransportationText"
        price=""
        eventname=""
        candelete="false"
      />

      <TransportationQty
        :quantity="item.quantity"
      />
    </div>
  </section>
</template>

<script>
import ItemDescription from './item_description'
import ItemQty from './item_quantity'
import TransportationQty from './event_transportation_quantity'
import PackageAndHotelJs from '../package_and_hotel_item_shared_methods'

export default Object.assign({}, PackageAndHotelJs, {
  components: {
    ItemDescription,
    ItemQty,
    TransportationQty
  }
})
</script>
