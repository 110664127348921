<template>
  <div class="hotel-rate-change-text">
    <p>
      <b>{{ $t("checkout.payment.headsUp") }}</b>
      {{ $t("checkout.payment.rateChanged", {total: nightlyRate} ) }}
    </p>
    <div class="buttons">
      <button
        class="btn btn-secondary"
        type="button"
        @click="acceptRateChange()"
      >
        {{ $t("h4h.proceedToCheckout") }}
      </button>
      <div
        class="reject"
        @click="rejectRateChange()"
      >
        {{ $t("h4h.seeOtherOptions") }}
      </div>
    </div>
  </div>
</template>
<script>
import CartApi from './cart_api'
import { mapActions } from 'vuex'

export default {
  data() {
    return{
      nightlyRate: 0,
      updatedItemId: 0,
      updateHotelId: 0,
      eventId: 0
    }
  },
  computed: {
    order(){
      return this.$store.state.order.order
    }
  },

  created(){
    if(this.order.price_change_item){

      let priceChangeItem = this.order.items.find( item => {
        return item.price_change
      })

      this.eventId = priceChangeItem.event_id
      this.nightlyRate = priceChangeItem.hotel_nightly_rate
      this.updatedItemId = priceChangeItem.id
      this.updateHotelId = priceChangeItem.hotel_reservations[0].hotel_id
    }
  },
  methods: {
    ...mapActions('order', ['getBackendOrder']),
    rejectRateChange() {
      CartApi.deleteH4hHotelItem(this.updatedItemId, this.updateHotelId, 'DELETE', null).then( () => {
        window.location.href = `/cart/events/${this.eventId}/hotels`
      })
    },
    acceptRateChange(){
      const body = JSON.stringify({price_change_confirmation_needed: false})
      CartApi.confirmHotelRateChange(this.updatedItemId, 'PUT', body).then( () => {
        window.location.href = '/cart/checkout'
      })
    },
  },
}
</script>
