<template>
  <p :class="isMotoGP() ? 'currency-disclaimer': 'currency-disclaimer small'">
    <span v-if="onlyUsd || inPackageCurrency">
      {{ $t('allPurchases', { currency: packageCurrency }) }}
    </span>

    <span v-else-if="chargeableCurrency">
      {{ $t('currencyDisclaimerCharge', { currency: packageCurrency }) }}
    </span>

    <span v-else-if="currency != packageCurrency">
      {{ $t('currencyDisclaimer', { currency: packageCurrency }) }}
    </span>
  </p>
</template>

<script>
import CurrencyApi from './currency-api'

export default {

  props: {
    currency: {
      default: 'USD',
      type: String,
    },
    packageCurrency: {
      default: 'USD',
      type: String,
    },
    chargeablecurrencies: {
      default: () => [],
      type: Array
    },
  },
  data() {
    return {
      allCurrencies: null
    }
  },

  computed: {
    onlyUsd() {
      if (this.allCurrencies) {
        return this.allCurrencies.length <= 1
      } else if (this.chargeablecurrencies) {
        return this.chargeablecurrencies.length <= 1
      } else {
        return true
      }
    },

    inPackageCurrency(){
      return this.currency == this.packageCurrency
    },

    chargeableCurrency() {
      if (this.chargeablecurrencies) {
        // USD will always be a chargable currency if package currency isn't USD
        return this.chargeablecurrencies.includes(this.currency) || (this.currency == 'USD' && this.packageCurrency != 'USD')
      } else {
        return false
      }
    }
  },

  watch: {
    chargeablecurrencies() {
      this.$emit('only-usd-emit', this.onlyUsd)
    }
  },

  mounted() {
    CurrencyApi.availableCurrencies().then(currencies => {
      if (currencies.currencies) {
        this.allCurrencies = currencies.currencies
      }

      this.$emit('only-usd-emit', this.onlyUsd)
    })
  },

  methods: {
    isMotoGP() {
      return window.name == 'motogp'
    }
  }
}
</script>
