<template>
  <section
    v-if="item.hotel_name || item.hotel_price_total"
    class="hotel-item"
  >
    <div class="desc-quantity-holder">
      <ItemDescription
        :itemid="item.id"
        :item="item"
        :name="hotel_display_name"
        :price="item.price_decorated"
        :package-id="item.package_id"
        candelete="true"
        is-hotel-item="true"
      />

      <div
        v-if="ers"
        class="errors"
      >
        <span>{{ ers }}</span>
        <span
          class="delete"
          @click="ers = null"
        />
      </div>

      <!-- <ItemQty
    :item="item"
    :quantity="item.room_quantity"
    :maxqty="hotelMaxQty"
    :updating="updating"
    candelete="true"
    @update-qty="updateItem"
    /> -->

      <!-- {{ this.item.room_quantity }} -->
      <div class="elements-item-quantity">
        <span> {{ $t('confirmation.quantity') }}</span>
        <div class="item-amount">
          {{ isQuoteOrder ? item.quantity : item.room_quantity }}
        </div>
      </div>
    </div>
    <div class="delete-holder">
      <i
        v-if="!cartdisabled"
        class="ion ion-md-close-circle-outline"
        @click="deleteItem"
      />
    </div>
  </section>
</template>

<script>
import { mapActions, mapMutations } from 'vuex'
import Api from '../../cart_api'
import moment from 'moment'

import ItemDescription from './item_description'
// import ItemQty from './item_quantity'

export default {
  components: {
    ItemDescription,
    // ItemQty
  },

  props: {
    item: {
      default: () => {},
      type: Object
    }
  },

  data() {
    return {
      ers: null,
      updating: false
    }
  },

  computed: {
    order() {
      return this.$store.state.order.order
    },
    isQuoteOrder() {
      return this.$store.state.order.order.order_type === 'QuoteOrder'
    },
    hotel_display_name() {
      if (this.isQuoteOrder) 
        return `${this.item.hotel_display_name}`
      else
        return `${this.item.package_display_name} -
                ${this.item.hotel_display_name}`
    },
    blankH4HHotel(){
      return !this.item.hotel_name && !this.item.hotel_price_total_decorated
    },

    hotelMaxQty() {
      return Math.min(this.item.hotel_inventory_on_site, 10)
    },

    eventTransportationText() {
      return this.$t('cart.transportationText')
    },
    cartdisabled(){
      return (this.$store.state.order.order.disabled_cart)
    },
    reformatCheckIn(){
      let checkIn = this.item.hotel_reservations[0] ? this.item.hotel_reservations[0].hotel_check_in_date : this.item.contract_check_in
      if (checkIn === null) 
        return ''
      else
        return `${this.$t('h4h.checkIn')}: ${moment(checkIn).format('MMMM D, YYYY')}`
    },
    reformatCheckOut(){
      let checkOut = this.item.hotel_reservations[0] ? this.item.hotel_reservations[0].hotel_check_out_date : this.item.contract_check_out
      if (checkOut === null) 
        return ''
      else
        return `${this.$t('h4h.checkOut')}: ${moment(checkOut).format('MMMM D, YYYY')}`
    },
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('order', ['updateLoading']),
    deleteItem() {
      this.ers = null
      this.updateLoading(true)
      Api.changeOrderItem(this.item.id, 'DELETE', null).then(item => {
        if (item.errors) { this.ers = item.errors }
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })
    },
    updateItem(newHotelQty) {
      this.ers = null
      this.updating = true
      let body = JSON.stringify({ order_item: { room_quantity: newHotelQty } })

      Api.changeOrderItem(this.item.id, 'PUT', body).then(() => {
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })
    }
  }
}
</script>
