<template>
  <div
    class="form-container guest-form-container"
  >
    <div class="block guest-checkout">
      <h3>Guest Checkout</h3>
      <p>Checkout as a guest and complete your order - easy!</p>
    </div>
    <form
      id="guest_customer"
      novalidate="novalidate"
      class="simple_form new_customer"
      :action="geustsControllerInLocale"
      accept-charset="UTF-8"
      data-remote="true"
      method="post"
    >
      <input
        name="utf8"
        type="hidden"
        value="✓"
      >
      <input
        type="hidden"
        name="authenticity_token"
        :value="getToken"
      >

      <div class="form-group field">
        <input
          id="guestSubmitBtn"
          type="submit"
          name="commit"
          value="Checkout as Guest"
          class="btn btn-primary btn-block guest-acct-btn"
        >
      </div>
    </form>
  </div>
</template>

<script>
export default {
  name: 'GuestForm',
  computed: {
    geustsControllerInLocale() {
      return `/customers/guests?locale=${window.locale}`
    },
  },
  methods: {
    getToken() {
      let tok = document.getElementsByTagName('meta')['csrf-token']
      if (tok) { // csrf-token is null in testing
        tok.getAttribute('content')
      }
    }
  }
}
</script>
