<template>
  <section
    v-if="item.hotel_price_total > 0"
    class="hotel-add-on"
  >
    <ItemDescription
      :itemid="item.id"
      :name="item.hotel_display_name"
      :price="item.hotel_price_decorated"
      :check-in="assignCheckIn"
      :check-out="assignCheckOut"
      :eventname="hotelEventName"
      candelete="true"
    />

    <div
      v-if="ers"
      class="errors"
    >
      <span>{{ ers }}</span>
      <span
        class="delete"
        @click="ers = null"
      />
    </div>

    <div
      :class="[ hotel_display_name.length > 39 ? 'overhead-margin' : '', 'item-quantity' ]"
    >
      <p
        id="itemAmount"
        class="item-amount"
      >
        {{ item.room_quantity }}
      </p>
    </div>
    <div
      v-if="h4hAddOn && isContracted"
      class="transportation-description"
    >
      <ItemDescription
        :itemid="item.id"
        :name="transportationFee"
        eventname=""
        candelete="false"
      />

      <ItemQty
        :item="item"
        :quantity="item.quantity"
        :maxqty="maxTransportationQty"
        :minqty="item.room_quantity"
        :updating="updating"
        candelete="false"
        @update-qty="updatePackage"
      />
    </div>
  </section>
  <section
    v-else-if="item.hotel_in_cart"
    class="no-package-in-cart"
  >
    <div class="message">
      {{ $t('cart.cartEmpty') }}
    </div>
  </section>
</template>

<script>
import ItemDescription from '../h4h/item_description'
import ItemQty from './item_quantity'
import PackageAndHotelJs from '../package_and_hotel_item_shared_methods'

export default
Object.assign({}, PackageAndHotelJs, {
  components: {
    ItemDescription,
    ItemQty
  }
})
</script>
