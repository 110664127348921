<template>
  <div
    class="form-container signup-form-container block"
  >
    <div>
      <h4>{{ $t('signIn.createAccount') }}</h4>
      <p>{{ $t('signIn.createDesc') }}</p>
    </div>
    <VForm
      id="new_customer"
      novalidate="novalidate"
      class="simple_form new_customer new_customer_checkout_form block"
      action="/customers"
      accept-charset="UTF-8"
      data-remote="true"
      method="post"
    >
      <input
        name="utf8"
        type="hidden"
        value="✓"
      >
      <input
        name="authenticity_token"
        type="hidden"
        :value="csrfToken()"
      >
      <div
        v-if="isRoomCash()"
        class="form-group"
      >
        <Field
          id="new_customer_first_name"
          :placeholder="$t('form.first_name')"
          class="form-control"
          value=""
          type="text"
          name="customer[first_name]"
          :rules="usernameRequirement"
        />
      </div>
      <ErrorMessage
        as="label"
        name="customer[first_name]"
        class="error help is-danger is-italic"
      />
      <div
        v-if="isRoomCash()"
        class="form-group"
      >
        <Field
          id="new_customer_last_name"
          :placeholder="$t('form.last_name')"
          class="form-control"
          value=""
          type="text"
          name="customer[last_name]"
          rules="required"
        />
        <ErrorMessage
          as="label"
          name="customer[last_name]"
          class="error help is-danger is-italic"
        />
      </div>
      <div
        class="form-group field"
      >
        <Field
          id="new_customer_email"
          v-model="signupEmail"
          :placeholder="$t('form.email')"
          class="form-control input"
          value=""
          type="email"
          name="customer[email]"
          :rules="validateEmail"
        />
        <div>
          <ErrorMessage
            as="label"
            name="customer[email]"
            class="error help is-danger is-italic"
          />
        </div>
      </div>

      <div class="form-group field">
        <Field
          id="new_customer_password"
          ref="customer[password]"
          class="form-control input"
          :placeholder="$t('form.password')"
          autocomplete="off"
          type="password"
          name="customer[password]"
          data-vv-as="password"
          data-vv-validate-on="input"
          :rules="validatePassword"
        />
        <div>
          <ErrorMessage
            as="label"
            name="customer[password]"
            class="error help is-danger is-italic"
          />
        </div>
        <div class="form-req-holder">
          <em class="form-req">{{ $t('passwordComplexity') }}</em>
        </div>
      </div>

      <div class="form-group field">
        <Field
          id="new_customer_password_confirmation"
          class="form-control input"
          autocomplete="off"
          :placeholder="$t('signIn.confirmPassword')"
          type="password"
          name="customer[password_confirmation]"
          data-vv-validate-on="input"
          data-vv-as="passwordConfirmation"
          rules="confirmed:@customer[password]"
          @keyup.enter="submit"
        />
        <ErrorMessage
          as="label"
          name="customer[password_confirmation]"
          class="error help is-danger is-italic"
        />
      </div>
      <div class="form-group field">
        <input
          id="submitBtn"
          type="submit"
          name="commit"
          :value="createAcctBtnText"
          class=" btn-block create-acct-btn btn btn-primary"
          data-disable-with="Sign up"
        >
      </div>
    </VForm>
    <div
      v-if="oauthText"
      class="continue-text"
    >
      or continue with
    </div>
    <div class="oauth-block columns is-mobile">
      <form
        v-if="google()"
        class="google-oauth-form column control"
        method="post"
        action="/auth/google"
        data-turbo="false"
      >
        <input
          id="signup-oauth-google"
          class="oauth-button-google input is-fullwidth"
          type="submit"
          value="GOOGLE"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken()"
        >
      </form>
      <form
        v-if="microsoft()"
        class="microsoft-oauth-form column control"
        method="post"
        action="/auth/microsoft"
        data-turbo="false"
      >
        <input
          id="signup-oauth-microsoft"
          class="oauth-button-microsoft input is-fullwidth"
          type="submit"
          value="MICROSOFT"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken()"
        >
      </form>
      <form
        v-if="apple()"
        class="apple-oauth-form column control"
        method="post"
        action="/auth/apple"
        data-turbo="false"
      >
        <input
          id="signup-oauth-apple"
          class="oauth-button-apple input is-fullwidth"
          type="submit"
          value="APPLE"
        >
        <input
          type="hidden"
          name="authenticity_token"
          :value="csrfToken()"
        >
      </form>
    </div>
  </div>
</template>

<script>
import { Field, Form as VForm, ErrorMessage, defineRule } from 'vee-validate'
import * as yup from 'yup'


defineRule('confirmed', (value, [target]) => {
  if (value === target) {
    return true
  }
  return 'Passwords must match'
})

export default {
  name: 'DefaultCustomersSignup',
  components: {
    Field,
    VForm,
    ErrorMessage
  },
  // eslint-disable-next-line vue/require-prop-types
  props: ['tok'],

  data() {
    return {
      disabled: true,
      createAcctBtnText: ''
    }
  },
  // computed: Form({
  //   originPassword: 'customer[password]',
  //   confirmedPassword: 'customer[password_confirmation]'
  // }),

  computed: {
    oauthText(){
      if (this.google()) {
        return true
      } else if (this.microsoft()) {
        return true
      } else if (this.apple()) {
        return true
      } else {
        return false
      }
    },

    signupEmail: {
      // get() {
      //   return this.$store.state.login.email
      // },
      set(email) {
        this.$store.state.login.email = email
      }
    },

    usernameRequirement(){
      if (this.quoteOrder){
        return false // can be false for quote orders
      } else {
        return yup.string().required()
      }
    },
    quoteOrder(){
      return document.getElementById('quoteOrder')
    }
  },
  mounted() {
    this.submitText()
    this.findQuoteEmail()
  },

  methods: {

    validateEmail(emailValue){
      if(this.quoteOrder){
        return true
      } else {

        if (!emailValue) {
          return 'This field is required'
        }
        // if the field is not a valid email
        const regex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,12}$/i

        if (!regex.test(emailValue)) {
          return 'Please enter a valid email'
        }
        // All is good
        return true
      }
    },

    validatePassword(password){
      if (!password) {
        return 'This field is required'
      }
      // if the field is not a valid email
      const regex = /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/

      if (!regex.test(password)) {
        return 'Please enter a valid password'
      }
      // All is good
      return true
    },

    submit() {
      var input = document.getElementById('customer_password_confirmation')
      input.addEventListener('keyup', function(e) {
        if (e.key === 'Enter') {
          document.getElementById('submitBtn').click()
        }
      })
    },
    findQuoteEmail(){
      // let quoteOrder = document.getElementById('quoteOrder')
      if(this.quoteOrder){
        let quoteEmail = this.quoteOrder.dataset.email
        document.getElementById('new_customer_email').value = quoteEmail
        document.getElementById('new_customer_email').classList.add('quote-disabled')
      }
    },
    submitText(){
      let path = '/login'
      if(document.getElementById('gaPathOverride')){
        path = document.getElementById('gaPathOverride').dataset.url
      }
      if(path == '/login') {
        this.createAcctBtnText = this.$t('signIn.createAccount')
      } else {
        this.createAcctBtnText = 'Create Account & Checkout'
      }
    },

    csrfToken(){
      let tok = document.getElementsByTagName('meta')['csrf-token']
      if (tok) { // csrf-token is null in testing
        return tok.getAttribute('content')
      } else {
        return null
      }
    },

    google(){
      document.getElementById('#google-oauth-login')
      return document.getElementById('google-oauth-login')
    },

    microsoft(){
      document.getElementById('#microsoft-oauth-login')
      return document.getElementById('microsoft-oauth-login')
    },

    apple(){
      document.getElementById('#apple-oauth-login')
      return document.getElementById('#apple-oauth-login')
    },

    isRoomCash() {
      return (window.brand_name == 'RoomCash')
    }
  }
}
</script>
