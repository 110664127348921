<template>
  <div
    v-if="order.gpt"
    class="gpt-cart-display"
  >
    <p class="header">
      {{ $t('cart.shippingFee') }}
    </p>
    <p class="amount">
      {{ order.gpt_amount_decorated }}
    </p>
  </div>
</template>

<script>
export default {
  computed: {
    order(){
      return this.$store.state.order.order
    }
  }
}
</script>
