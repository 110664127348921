<template>
  <section
    v-if="item.price > 0 || !item.h4h_hotel_add_on"
    class="package-item"
  >
    <div v-if="showParkingPassText">
      <ParkingPass
        :item="item"
      />
    </div>
    <div
      v-else
      class="single-item"
    >
      <ItemDescription
        :itemid="item.id"
        :name="item.package_display_name"
        :price="item.price_decorated"
        :eventname="item.event_display_name"
        :checkin="item.contract_check_in"
        :checkout="item.contract_check_out"
        :package-id="item.package_id"
        :candelete="cartdisabled ? 'false': 'true'"
        :product-names="item.product_names"
        :cartdisabled="cartdisabled"
        :producttype="item.product_type"
      />

      <div
        v-if="ers"
        class="errors"
      >
        <span>{{ ers }}</span>
        <span
          class="delete"
          @click="ers = null"
        />
      </div>
      <ItemQty
        :item="item"
        :quantity="item_quantity"
        :maxqty="maxPackageQty"
        :updating="updating"
        :candelete="cartdisabled ? 'false': 'true'"
        :cartdisabled="cartdisabled"
        @update-qty="updateItem"
        @delete-item="deleteItem"
      />
    </div>
  </section>
</template>

<script>
import ItemDescription from './item_description'
import ItemQty from './item_quantity'
import PackageOnlyJs from '../package_only_item_shared_methods'
import ParkingPass from './parking_pass'

export default
Object.assign({}, PackageOnlyJs, {
  components: {
    ItemDescription,
    ParkingPass,
    ItemQty
  }
})
</script>
