<template>
  <div class="item-quantity">
    <p
      class="disable subtract"
    />
    <p
      id="itemAmount"
      class="item-amount"
    >
      {{ quantity }}
    </p>
    <p
      class="disable add"
    />
  </div>
</template>

<script>
export default {

  props: {
    quantity: {
      default: null,
      type: Number
    },
  },
}
</script>
