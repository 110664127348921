/* global locale */
import Api from '../cart_api'
import { mapActions, mapMutations } from 'vuex'

export default {
  computed: {
    maxPackageQty() {
      return Math.min(this.item.inventory_on_site, this.maxDepositQty, this.item.max_package_qty, 10)
    },

    minPackageQty() {
      return Math.max(this.item.min_package_qty, 1)
    },

    maxDepositQty(){
      if(this.order && this.order.max_deposit_package_quantity){
        return this.order.max_deposit_package_quantity
      }else {
        return 10
      }
    },

    showParkingPassText(){
      return this.item.parking_pass && !this.item.parking_pass_accepted
    },

    orderItemAddHotelLink() {
      return `/${locale}/cart/events/${this.item.event_id}/hotels`
    },

    orderItemLinkText() {
      if (this.item.package_hotel_required) {
        return this.$t('cart.mustAddHotel')
      } else {
        return this.$t('cart.addHotel')
      }
    },

    order(){
      return this.$store.state.order.order
    },
    updating(){
      return this.$store.state.order.loading
    },

    item_quantity() {
      this.$store.state.order_item.quantity = this.item.quantity

      return this.item.quantity
    },
  },

  data() {
    return {
      ers: null
    }
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    ...mapMutations('cart', ['displayCart']),
    ...mapMutations('order', ['updateLoading']),
    ...mapMutations('checkout', ['updateRewardPoints']),
    ...mapMutations('order_item',['updateTransportItemQuantity']),

    deleteItem() {
      this.ers = null
      Api.changeOrderItem(this.item.id, 'DELETE', null).then(item => {
        if (item.errors) { this.ers = item.errors }
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })
    },
    updateItem(newPackageQty) {
      this.ers = null
      let body = JSON.stringify({ order_item: { quantity: newPackageQty, apply_credit:this.$store.state.checkout.applyCredit } })

      Api.changeOrderItem(this.item.id, 'PUT', body).then(item => {
        if (item.errors) { this.ers = item.errors }
        if(item.reward_points){ this.updateRewardPoints(item.reward_points)}
        this.getBackendOrder().then( () => this.updateLoading(false) )
      })

      this.updateTransportItemQuantity(newPackageQty)
    }
  },

  props: ['item', 'showhotels', 'cartdisabled']
}
