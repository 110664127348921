<template>
  <section v-if="order != null">
    <CartDisplay
      v-if="order.items && order.items.length > 0"
      :loading="loadingOrder"
      :checkoutpage="checkoutpage"
    />

    <CartEmpty v-else />
  </section>
</template>

<script>
/* global Turbo */
import Api from './cart_api'
import CartEmpty from './empty_display'
import CartDisplay from './cart_display'
import { mapMutations, mapGetters, mapActions } from 'vuex'

export default {
  name: 'CartDefault',
  components: {
    CartDisplay,
    CartEmpty,
  },
  props: {
    updateorder: {
      default: false,
      type: Boolean
    },
    checkoutpage: {
      default: null,
      type: Boolean
    }
  },

  data() {
    return {
      loadingOrder: false
    }
  },
  computed: {
    order() {
      return this.$store.state.order.order
    },
    orderItems(){
      return this.$store.state.order.order.items
    },
    invalidItems() {
      if (this.order) {
        return this.order.items.filter(item => {
          return item.hotel_id == null && item.package_hotel_required
        })
      } else {
        return []
      }
    },
    hotelNeeded(){
      if (this.order) {
        return this.order.hotel_needed
      } else {
        return []
      }
    },
    invalidItemsInCart() {
      return this.invalidItems.length > 0
    }
  },
  created() {
    this.getOrder()
  },
  updated(){
    if(this.order.cart_version==='h4h' && this.order.items.length===0 &&
    document.getElementById('h4hHotelIndex')){
      Turbo.visit('/packages')
    }
  },
  methods: {
    ...mapMutations('cart', ['displayCart']),
    ...mapMutations('order', ['updateOrder', 'updateCart']),
    ...mapGetters('order', ['getCurrentOrder', 'emptyOrder']),
    ...mapActions('order', ['getBackendOrder']),

    async getOrder() {
      this.loadingOrder = true
      if(this.emptyOrder()){
        await this.getBackendOrder()
        this.loadingOrder = false

      }
    },

    clearCart() {
      Api.clearCart().then( () => {
        this.getBackendOrder().then( () => this.displayCart())
      })
    },
  }
}
</script>
