<template>
  <div class="item-description">
    <div class="item-container">
      <a
        v-if="packageId"
        :href="packagePath"
        class="in-cart-package-link"
      >
        <p class="name"> {{ name }}</p>
      </a>
      <a
        v-else-if="hotelId"
        :href="hotelPath"
        class="in-cart-package-link"
      >
        <p class="name"> {{ name }}</p>
      </a>
      <p
        v-else
        class="name"
      >
        {{ name }}
        <i
          v-if="order && order.order_type!='WebOrder'"
          class="fa fa-question-circle"
          @click="isModalVisible = true"
        />
      </p>
      <div
        v-if="order && order.order_type!='WebOrder'"
        class="quote-products-modal"
      >
        <QuoteProductsModal
          v-show="isModalVisible"
          :product-names="productNames"
          :name="name"
          @close="closeModal"
        />
      </div>
      <p
        v-if="isGPE()"
        class="event-name"
        :class="[ eventname == '' ? 'hide' : '' ]"
      >
        {{ eventlabel || eventname }}
      </p>
      <p
        v-else
        class="event-name"
        :class="[ eventname == '' ? 'hide' : '' ]"
      >
        {{ eventname }}
      </p>
      <p
        class="hotel-duration"
        :class="[ hasDate ? 'hide' : '' ]"
      >
        {{ reformatDates() }}
      </p>
    </div>
    <div class="price-container">
      <p class="price">
        {{ price }}
      </p>
      <p
        v-if="candelete == &quot;true&quot;"
        class="delete"
        @click="deleteOrderItem"
      />
      <p
        v-else
        class="delete"
        :class="[ hotelId == 0 && packageId == 0 ? 'hide' : '' ]"
        @click="deleteHotelItem"
      />
    </div>
    <div
      v-if="ers"
      class="errors"
    >
      <span>{{ ers }}</span>
      <span
        class="delete"
        @click="ers = null"
      />
    </div>
  </div>
</template>

<script>
/* global locale */
import Api from '../../cart_api'
import { mapActions, mapMutations } from 'vuex'
import QuoteProductsModal from '../version_1/q2oo_modal.vue'
import moment from 'moment'


export default {

  components: {
    QuoteProductsModal
  },

  props: {
    candelete: {
      type: String,
      default: ''
    },
    checkIn: {
      type: String,
      default: '',
      required: false
    },
    checkOut: {
      type: String,
      default: '',
      required: false
    },
    contracted: {
      type: Boolean,
      default: null
    },
    eventname: {
      type: String,
      default: ''
    },
    eventlabel: {
      type: String,
      default: ''
    },
    hotelId:{
      type: Number,
      default: 0
    },
    itemid: {
      type: Number,
      default:0
    },
    name: {
      type: String,
      default:''
    },
    price: {
      type: String,
      default:''
    },
    packageId: {
      type: Number,
      default:0
    },
    productNames: {
      type: Array,
      default: () => []
    }
  },

  data() {
    return {
      ers: null,
      // updating: false,
      chosenPackage:{},
      packagePath: `/${locale}/packages/${this.packageId}/path`,
      hotelPath: `/${locale}/cart/items/${this.itemid}/hotels`,
      dataName: '',
      dataPrice: null,
      isModalVisible: false
    }
  },

  computed: {
    hasDate() {
      if (this.checkIn == null || this.checkIn == '') {
        return true
      } else {
        return false
      }
    },
    updating(){
      return this.$store.state.order.loading
    },
    order(){
      return this.$store.state.order.order
    }
  },

  watch: {
    order(){
      this.setCart()
    }
  },

  methods: {
    ...mapMutations('order', ['updateLoading']),
    ...mapActions('order', ['getBackendOrder']),
    isGPE(){
      return window.brand_name=='Grand Prix Events'
    },
    reformatDates(){
      if(this.checkIn && this.checkOut) {
        moment.locale(locale)
        let splitCheckin = this.checkIn.split('/')
        let splitCheckout = this.checkOut.split('/')

        let dateCheckin = [splitCheckin[1],splitCheckin[0], splitCheckin[2]].join('/')
        let dateCheckout = [splitCheckout[1],splitCheckout[0], splitCheckout[2]].join('/')

        let checkin = moment(dateCheckin).format('L')
        let checkout = moment(dateCheckout).format('L')
        return `${checkin} - ${checkout}`
      }
    },
    deleteOrderItem() {
      if (this.updating) { return }
      this.ers = null
      this.updateLoading(true)
      Api.changeOrderItem(this.itemid, 'DELETE', null).then( () => {
        this.getBackendOrder().then( () => this.updateLoading(false))
        if(document.getElementById('packageInCartDisplay')){
          document.getElementById('packageInCartDisplay').classList.add('hidden')
        }
      })
    },

    deleteHotelItem() {
      if (this.updating) { return }
      this.ers = null
      this.updateLoading(true)
      if(this.contracted == true) {
        Api.deleteContractedHotelItem(this.itemid, this.hotelid, 'DELETE', null).then(item => {
          if (item.errors) { this.ers = item.errors }
          this.updateLoading(false)
        })
      } else {
        this.removeRetailHotel()
      }
    },

    removeRetailHotel(){
      Api.deleteH4hHotelItem(this.itemid, this.hotelid, 'DELETE', null).then(item => {
        if (item.errors) { this.ers = item.errors }
        this.getBackendOrder().then( () => this.updateLoading(false))
      })
    },

    setCart(){
      if(this.packageId){
        this.packageQty=this.order.item_count
        this.orderId=this.order.id
        this.orderItemId=this.itemid
      }
      else {
        this.setPackage(this.order)
      }
    },

    setPackage(order){
      this.chosenPackage=order.items.find(orderItem=>{
        return orderItem.id==this.itemid
      })
    },

    closeModal() {
      this.isModalVisible = false
    }
  }
}
</script>
