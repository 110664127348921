<template>
  <div :class="['cart-footer', extraclass]">
    <p class="header">
      {{ header }}
    </p>
    <p class="amount">
      {{ amount }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    header: {
      default: null,
      type: String
    },
    amount: {
      default: null,
      type: String
    },
    extraclass: {
      default: null,
      type: String
    }}
}
</script>
