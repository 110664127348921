<template>
  <div class="item-quantity">
    <p
      :class="[disableSubtract ? 'disable' : '', 'subtract', deposit_qty_match] "
      @click="decreaseQty"
    />
    <p
      id="itemAmount"
      class="item-amount"
    >
      {{ quantity }}
    </p>
    <p
      :class="[disableAdd ? 'disable' : '', 'add', deposit_qty_match]"
      @click="increaseQty"
    />
  </div>
</template>

<script>
import { mapMutations } from 'vuex'

export default {

  props: {
    candelete: {
      default: null,
      type: String
    },
    disablesubtract:{
      default: false,
      type: Boolean
    },
    maxqty: {
      default: null,
      type: Number
    },
    minqty: {
      default: null,
      type: Number
    },
    quantity: {
      default: null,
      type: Number
    },
    item: {
      default: () => {},
      type: Object
    },
    hotel: {
      default: false,
      type: Boolean
    },
    updating: {
      default: false,
      type: Boolean
    }
  },
  computed: {
    disableAdd() {
      return this.quantity >= this.maxqty || this.updating == 'true' || this.cartdisabled || this.parkingPassItem ||
        (this.notEnoughRequiredQty && !this.hotel)
    },

    qtyRequirementExists(){
      return this.item && this.item.quantity_required
    },

    notEnoughRequiredQty (){
      if(!this.qtyRequirementExists ) {return false}

      return this.maxqty < this.item.quantity_required + this.quantity
    },

    // qtyRequired(){
    //   // return this.selectedPackage.quantity_required
    // },

    parkingPassItem(){
      if(this.item && this.parkingPass){
        return this.quantity >= this.item.parking_pass_max_qty
      } else {
        return false
      }
    },

    parkingPass(){
      return this.item && this.item.parking_pass==true
    },
    cartdisabled(){
      return this.$store.state.order.order.disabled_cart
    },

    order(){
      return this.$store.state.order.order
    },

    deposit_qty_match(){
      return (this.order && this.order.deposit_qty_exact_match ? 'hidden disable' : '')
    },

    ten_required(){
      return this.item && this.item.ten_required
    },

    emitingQty(){
      if(this.hotel){
        return 1
      } else {
        return this.item.quantity_required || 1
      }
    },

    disableSubtract() {
      if (this.cartdisabled || this.disablesubtract || this.ten_required){
        return true
      }
      else if (this.candelete == 'true') {
        return this.updating == 'true'
      }
      else if (this.minqty){
        return this.quantity <= this.minqty || this.updating == 'true'
      }
      else {
        return this.quantity <= 1 || this.updating == 'true'
      }
    }
  },

  methods: {
    ...mapMutations('order',['updateLoading']),
    decreaseQty() {
      if (this.disableSubtract) { return }
      this.updateLoading(true)

      if (this.quantity <= this.emitingQty) {
        this.$emit('delete-item')
      } else {
        this.$emit('update-qty', this.quantity - this.emitingQty)
        this.updateRoomQty('subtractRoom')
      }
    },

    increaseQty() {
      if (this.disableAdd) { return }
      this.updateLoading(true)
      this.$emit('update-qty', this.quantity + this.emitingQty)
      this.updateRoomQty('addRoom')
    },

    updateRoomQty(action){
      let addRoom = document.getElementById('addRoom')
      if (action ==  'addRoom'){
        if (addRoom && addRoom.classList.value.includes('disable')){
          addRoom.classList.remove('disable')
          this.updateLoading(false)
        }
      }
      else if (action == 'subtractRoom' && document.getElementById('numberOfRooms').textContent == this.quantity){
        let subtractRoom = document.getElementById('subtractRoom')
        subtractRoom.click()
        setTimeout( function(){
          addRoom.classList.add('disable')
          this.updateLoading(false)
        }, 500)
      }

    }
  }
}
</script>
