<template>
  <div class="cart-currency-selection level">
    <p class="header">
      {{ $t('cart.currency') }}
    </p>

    <div 
      v-if="chargeablecurrencies.length > 0 && !disabledcart"
    >
      <CurrencyDropdown
        :currency="chargecurrency"
        :chargeablecurrencies="chargeablecurrencies"
      />
    </div>

    <p
      v-else
      class="currency_exchange_form_text"
    >
      {{ chargecurrency }}
    </p>
  </div>
</template>

<script>
import CurrencyDropdown from '../../default/currency_convert/currency-dropdown'

export default {
  components: {
    CurrencyDropdown
  },

  props: {
    chargeablecurrencies: {
      default: () => [],
      type: Array
    },
    chargecurrency: {
      default: 'USD',
      type: String
    },
    disabledcart: {
      default: false,
      type: Boolean
    }}
}
</script>
