<template>
  <section
    class="package-item parking-pass"
  >
    <div>
      {{ $t('parkingPass', {'parkingPassQty': parkingPassQty}) }}
    </div>
    <div class="button-container">
      <button
        class="btn proceed-to-checkout"
        @click="displayOrderItem"
      >
        {{ $t('yes') }}
      </button>
      <button
        class="btn no-btn-parking-pass"
        @click="removeOrderItem"
      >
        {{ $t('no') }}
      </button>
    </div>
  </section>
</template>

<script>
import CartApi from '../../cart_api'
import { mapActions } from 'vuex'

export default {

  props: {
    item: {
      default: () => {},
      type: Object
    }
  },
  computed:{
    parkingPassQty(){
      return this.item.quantity
    }
  },

  methods: {
    ...mapActions('order', ['getBackendOrder']),
    displayOrderItem(){
      let body = JSON.stringify({ order_item: { parking_pass_accepted: true } })
      CartApi.changeOrderItem(this.item.id, 'PUT', body).then (item => {
        if(item.parking_pass_accepted){
          this.getBackendOrder()
        }
      })
    },

    removeOrderItem(){
      CartApi.changeOrderItem(this.item.id, 'DELETE', null).then (() => {
        this.getBackendOrder()
      })
    }
  }
}
</script>
